<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.71 24.88">
    <path
      d="m9.37,10.3c-.41-.46-.9-.82-1.46-1.09-.57-.27-1.2-.41-1.87-.41-.56,0-1.07.08-1.51.22-.43.14-.8.32-1.1.53-.01.01-.03.02-.04.03v-3.2c0-.22-.18-.41-.41-.41H.41c-.22,0-.41.18-.41.41v13.84c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-6.06c0-.26.04-.52.13-.77.08-.23.2-.44.37-.62.16-.18.37-.32.61-.43.24-.11.53-.16.88-.16.64,0,1.11.16,1.42.49.32.33.48.84.48,1.52v6.04c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-6.54c0-.64-.11-1.26-.34-1.84-.23-.58-.54-1.1-.95-1.55Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m23.95,9.07h-2.57c-.22,0-.41.18-.41.41v.11c-.18-.12-.37-.23-.58-.33-.65-.31-1.39-.46-2.2-.46-.93,0-1.79.19-2.55.57-.74.37-1.38.85-1.9,1.42-.52.57-.92,1.22-1.2,1.94-.28.72-.43,1.44-.43,2.14s.13,1.45.39,2.16c.26.72.65,1.37,1.15,1.95.51.58,1.14,1.05,1.88,1.41.75.36,1.61.54,2.55.54.78,0,1.52-.15,2.19-.46.25-.11.48-.24.69-.39v.16c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-10.75c0-.22-.18-.41-.41-.41Zm-2.89,5.78c0,.39-.07.76-.21,1.1-.14.34-.34.63-.59.88-.25.25-.55.45-.87.6-.63.29-1.43.29-2.08,0-.33-.15-.63-.35-.88-.6-.25-.25-.44-.54-.59-.88-.14-.34-.21-.71-.21-1.1s.07-.74.21-1.08c.14-.34.34-.64.59-.9.24-.25.53-.45.86-.6.33-.15.68-.22,1.06-.22s.73.07,1.06.22c.33.15.62.35.86.6.24.26.44.56.58.9.14.34.21.7.21,1.08Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m37.29,10.74h0c-.51-.58-1.14-1.05-1.88-1.41-.75-.36-1.61-.54-2.55-.54-.73,0-1.44.14-2.09.41-.28.12-.54.26-.8.44v-.16c0-.22-.18-.41-.41-.41h-2.57c-.22,0-.41.18-.41.41v14.34c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-3.7c.18.12.37.23.58.33.65.31,1.39.46,2.2.46.93,0,1.78-.19,2.54-.55.75-.36,1.39-.84,1.91-1.43.52-.58.92-1.23,1.2-1.95.28-.72.43-1.43.43-2.14s-.13-1.45-.39-2.16c-.26-.72-.65-1.37-1.15-1.95Zm-1.98,4.11c0,.39-.07.76-.22,1.09-.15.34-.35.64-.6.89s-.55.45-.88.6c-.65.29-1.42.29-2.06,0-.33-.15-.62-.35-.87-.6-.25-.25-.44-.54-.59-.88-.14-.34-.21-.71-.21-1.1s.07-.74.21-1.08c.14-.34.34-.65.59-.9.24-.25.53-.46.85-.6.32-.14.66-.22,1.02-.22.39,0,.75.07,1.08.22.33.15.63.35.88.61.25.26.45.56.59.9.14.34.21.7.21,1.08Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m51,10.74h0c-.51-.58-1.14-1.05-1.88-1.41-.75-.36-1.61-.54-2.55-.54-.73,0-1.44.14-2.09.41-.28.12-.54.26-.8.44v-.16c0-.22-.18-.41-.41-.41h-2.57c-.22,0-.41.18-.41.41v14.34c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-3.7c.18.12.37.23.58.33.65.31,1.39.46,2.2.46.93,0,1.78-.19,2.54-.55.75-.36,1.39-.84,1.91-1.43.52-.58.92-1.24,1.2-1.95.28-.72.43-1.43.43-2.14s-.13-1.45-.39-2.16c-.26-.72-.65-1.37-1.15-1.95Zm-1.98,4.11c0,.39-.07.76-.22,1.09-.15.34-.35.64-.6.89-.25.25-.55.45-.88.6-.65.29-1.42.29-2.05,0-.33-.15-.62-.35-.87-.6-.25-.25-.44-.54-.59-.88-.14-.34-.21-.71-.21-1.1s.07-.74.21-1.08c.14-.34.34-.65.59-.9.24-.25.53-.46.85-.6.32-.14.66-.22,1.02-.22.39,0,.75.07,1.08.22.33.15.63.35.88.61.25.26.45.56.59.9.14.34.21.7.21,1.08Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m57.01,9.07l-2.63,1.02c-.2.03-.35.2-.35.4v9.73c0,.22.18.41.41.41h2.57c.22,0,.41-.18.41-.41v-10.75c0-.22-.18-.41-.41-.41Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m69.45,9.73c-1.56-.78-3.27-.8-4.71-.16-.54.24-1,.55-1.4.92v-1.34c0-.07-.04-.13-.1-.16-.06-.03-.14-.01-.18.04-.25.25-.63.38-1.13.38h-1.66c-.07,0-.14.05-.16.11-.02.07,0,.14.06.19.35.27.57.5.66.7.06.14.14.45.14,1.15v11.12c0,.45-.02.79-.07,1.02-.04.21-.11.38-.21.48-.1.11-.27.24-.5.38-.07.04-.1.12-.08.19.02.07.09.12.16.12h3.76c.08,0,.15-.05.17-.13.02-.08-.02-.16-.09-.19-.35-.17-.55-.38-.63-.65-.09-.3-.13-.74-.13-1.31v-8.56c0-.82.18-1.46.53-1.91.36-.45.77-.76,1.22-.91.47-.16.9-.24,1.28-.24.64,0,1.21.16,1.69.48.49.32.88.78,1.15,1.37.28.6.42,1.29.42,2.07,0,1.18-.29,2.12-.87,2.78-.57.65-1.42.99-2.51.99-.46,0-.86-.05-1.21-.14-.36-.1-.78-.25-1.26-.46-.07-.03-.15-.01-.2.05-.05.06-.05.14-.01.2l1.23,1.85s.05.05.09.07c.34.12.63.2.88.24.25.04.54.07.88.07,1.07,0,2.05-.26,2.92-.78.86-.52,1.54-1.23,2.02-2.12.48-.89.72-1.89.72-2.97,0-1.19-.27-2.22-.81-3.05-.54-.83-1.23-1.46-2.06-1.88Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m77.57,19.21h0c-.08-.21-.13-.61-.13-1.17V4.68c0-.07-.04-.13-.1-.16-.06-.03-.14-.01-.18.04-.25.25-.63.38-1.13.38h-1.66c-.07,0-.14.05-.16.11-.02.07,0,.14.05.19.26.21.45.38.56.5.1.11.16.25.19.43.03.19.05.5.05.92v10.99c0,.4-.02.71-.05.91-.03.18-.09.32-.19.44-.11.13-.29.3-.56.5-.06.05-.08.12-.05.19.02.07.09.11.16.11h3.76c.07,0,.14-.05.16-.12.02-.07,0-.15-.06-.19-.36-.27-.58-.51-.66-.71Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m91.1,18.56c-.57-.01-.99-.1-1.24-.25-.24-.15-.39-.33-.44-.56-.06-.26-.09-.63-.09-1.1v-3.05c0-.06,0-.35.01-.89,0-.56-.07-1.04-.22-1.43-.26-.65-.72-1.18-1.37-1.57-.65-.39-1.62-.58-2.89-.58-.55,0-1.09.05-1.58.15-.04,0-.08.04-.11.07l-1.51,2.28c-.04.06-.04.15.01.2.05.06.13.07.2.04.75-.35,1.33-.59,1.73-.71.39-.12.83-.18,1.3-.18.61,0,1.11.12,1.48.37.35.23.53.53.53.9,0,.26-.07.46-.21.6-.15.16-.4.3-.74.41-.36.12-.89.25-1.58.39-.67.13-1.22.25-1.64.36-.42.11-.82.26-1.19.45-.44.23-.8.57-1.07,1.01-.27.44-.4.94-.4,1.49,0,.48.13,1,.38,1.54.26.55.69,1.03,1.29,1.42.6.4,1.38.6,2.33.6.5,0,.94-.05,1.31-.13.04,0,.07-.03.09-.06l1.33-1.7c.05-.06.05-.14,0-.2-.04-.06-.12-.09-.19-.07-.36.11-.66.19-.89.23-.23.04-.48.06-.75.06-.72,0-1.28-.15-1.67-.46-.38-.3-.57-.7-.57-1.22,0-.82.52-1.34,1.59-1.6.95-.23,1.58-.38,1.86-.46.22-.06.47-.16.75-.28v2.81c0,.72.08,1.25.24,1.64.17.4.49.7.95.91.44.2,1.09.32,1.96.38.06,0,.12-.02.15-.08l.99-1.48c.03-.05.04-.12,0-.17-.03-.06-.08-.09-.15-.09Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m103.27,19.43c-.09-.11-.16-.27-.19-.46-.04-.2-.06-.52-.06-.93v-4.22c0-.85-.05-1.53-.15-2.02-.11-.51-.34-.96-.69-1.34-.34-.37-.82-.7-1.4-.95-.59-.26-1.24-.39-1.95-.39s-1.3.14-1.83.41c-.43.22-.89.58-1.36,1.08v-1.45c0-.07-.04-.13-.1-.16-.06-.03-.14-.01-.18.04-.1.1-.23.19-.39.27-.16.07-.4.11-.71.11h-1.57c-.07,0-.14.05-.16.11-.02.07,0,.14.06.19.35.27.57.5.66.7.06.14.14.45.14,1.15v6.52c0,.4-.02.71-.05.91-.03.18-.09.32-.19.44-.11.13-.29.3-.56.5-.06.04-.08.12-.06.19.02.07.09.11.16.11h3.76c.07,0,.14-.05.16-.12.02-.07,0-.15-.06-.19-.36-.27-.58-.51-.66-.71-.08-.22-.13-.61-.13-1.17v-4.9c0-.32.04-.58.11-.77.07-.19.18-.36.32-.51.25-.26.55-.45.88-.56.92-.29,1.74-.18,2.42.17.42.22.71.49.87.8.2.38.3.98.3,1.81v3.96c0,.54-.05.93-.14,1.18-.09.23-.31.47-.67.7-.06.04-.09.12-.07.19.02.07.09.12.16.12h3.79c.07,0,.14-.05.16-.11.02-.07,0-.14-.06-.19-.26-.21-.44-.37-.54-.5Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m114.08,15.03c-.41-.45-.87-.79-1.38-1.01-.49-.21-1.14-.44-1.91-.68-.84-.27-1.43-.51-1.76-.74-.3-.2-.44-.43-.44-.69,0-.31.16-.56.5-.77.36-.22.84-.33,1.42-.33.47,0,.92.07,1.33.21.42.14.89.36,1.37.65.05.03.12.03.17,0,.05-.03.09-.09.09-.15v-1.94c0-.08-.05-.14-.13-.16-.74-.2-1.61-.31-2.58-.31-1.42,0-2.55.29-3.37.85-.83.58-1.26,1.38-1.26,2.38,0,.52.11.96.34,1.33.22.36.58.69,1.08.99.48.29,1.16.59,2.03.9.08.03.15.05.21.08.08.03.15.06.2.07.61.21,1.07.39,1.36.52.28.12.49.28.65.47.15.18.22.4.22.68,0,.4-.2.73-.62,1-.44.28-1,.42-1.68.42s-1.37-.11-1.98-.31c-.61-.21-1.33-.57-2.15-1.06-.06-.04-.14-.03-.19.01-.06.05-.08.12-.05.19l.77,2.24s.06.09.1.1c.58.2,1.09.35,1.54.44.44.09.97.13,1.55.13,1.05,0,1.97-.15,2.75-.45.79-.3,1.4-.74,1.82-1.3.42-.57.64-1.22.64-1.94s-.21-1.33-.64-1.8Z"
      style="fill: #003241; stroke-width: 0px"
    />
    <path
      d="m60.4.29s-.08.09-.11.14c-1.19,1.52-2.7,2.93-4.59,3.33-1.54.32-3.18-.09-4.51-.93-.23-.15-.45-.3-.71-.39-1.49-.52-.77,1.61-.43,2.21.46.83.98,1.63,1.67,2.28s1.56,1.13,2.5,1.23c1.67.18,3.24-.84,4.36-2.09,1.4-1.55,2.33-3.52,2.65-5.58.02-.1.03-.21-.01-.3-.08-.18-.33-.23-.51-.15-.13.05-.23.15-.32.25Z"
      style="fill: #00ce7f; stroke-width: 0px"
    />
  </svg>
</template>

<style scoped></style>
